import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Text from "./text"
import theme from "../theme"

const TextBlockContainer = styled.section`
  text-align: center;
  color: ${theme.color.white};
  font-size: 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${theme.layout.s.width}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${10 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${10 * theme.layout.l.grid * 100}vw;
    margin: 0 auto;
  }
`

const TextBlock = (props) => {
  return (
    <TextBlockContainer data-bg={props.bg} id={props.id}>
      <Text {...props}>{props.children}</Text>
    </TextBlockContainer>
  )
}

Text.propTypes = {
  bg: PropTypes.string,
}

Text.defaultProps = {
  siteTitle: ``,
}

export default TextBlock
